/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react';
import {Link} from 'react-router-dom';
import {Languages} from './Languages';
import {KTSVG} from '../../../helpers';
import clsx from 'clsx';
import {useAppDispatch} from 'core/store/hooks';
import {actions} from 'core/store/auth';
import {useSelector} from 'react-redux';
import {useSession} from 'core/store/core/hooks';
import {ShowForGlobalPermission} from 'core/permissions/ShowForGlobalPermission';
import {globalPermissions} from 'core/permissions/globalPermissionHelper';
import {
  ThemeModeType,
  useThemeMode,
} from 'core/layouts/layout2/partials/theme-mode/ThemeModeProvider';
import {useTranslate} from 'core/i18n/i18nProvider';

const HeaderUserMenu: FC = () => {
  const dispatch = useAppDispatch();
  const {user} = useSession();
  const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode();
  const {t} = useTranslate();
  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode);
    updateMode(_mode);
  };

  const doLogout = () => {
    dispatch(actions.logout());
  };
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <div className='w-50px h-50px'>
              <span className='symbol-label bg-light-danger text-danger fw-bold position-absolute'>
                {user?.name?.[0] || ''}
              </span>
              {user?.avatar && (
                <img
                  src={user.avatar}
                  alt='avatar'
                  className='position-relative w-100 h-100 rounded'
                  style={{zIndex: 10}}
                  onError={(e: any) => {
                    e.target.style.display = 'none';
                  }}
                />
              )}
            </div>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.name}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'></span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to='/profile' className='menu-link px-5'>
          {t('user_header_menu_profile_setting')}
        </Link>
      </div>
      <div className='separator my-2'></div>

      <ShowForGlobalPermission permission={globalPermissions.userManagement}>
        <div className='menu-item px-5'>
          <Link to='/admin' className='menu-link px-5'>
            {t('user_header_menu_account_setting')}
          </Link>
        </div>
        <div className='separator my-2'></div>
      </ShowForGlobalPermission>

      <div className='menu-item px-5'>
        <Link to='/app-manager/home' className='menu-link px-5'>
          {t('user_header_menu_app_setting')}
        </Link>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to='/developer' className='menu-link px-5'>
          {t('user_header_menu_developer')}
        </Link>
      </div>
      <div className='separator my-2'></div>
      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>{t('user_header_menu_theme')}</span>
          <span className='menu-arrow'></span>
        </a>
        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a
              href='#'
              className={clsx('menu-link px-3 py-2', {active: menuMode === 'light'})}
              onClick={() => switchMode('light')}
            >
              <span className='menu-icon' data-kt-element='icon'>
                <KTSVG path='/media/icons/duotune/general/gen060.svg' className='svg-icon-3' />
              </span>
              <span className='menu-title'>{t('user_header_menu_theme_light')}</span>
            </a>
          </div>

          <div className='menu-item px-3'>
            <a
              href='#'
              className={clsx('menu-link px-3 py-2', {active: menuMode === 'dark'})}
              onClick={() => switchMode('dark')}
            >
              <span className='menu-icon' data-kt-element='icon'>
                <KTSVG path='/media/icons/duotune/general/gen061.svg' className='svg-icon-3' />
              </span>
              <span className='menu-title'>{t('user_header_menu_theme_dark')}</span>
            </a>
          </div>

          <div className='menu-item px-3'>
            <a
              href='#'
              className={clsx('menu-link px-3 py-2', {active: menuMode === 'system'})}
              onClick={() => switchMode('system')}
            >
              <span className='menu-icon' data-kt-element='icon'>
                <KTSVG path='/media/icons/duotune/general/gen062.svg' className='svg-icon-3' />
              </span>
              <span className='menu-title'>{t('user_header_menu_theme_system')}</span>
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5'>
        <a onClick={doLogout} className='menu-link px-5'>
          {t('user_header_menu_log_out')}
        </a>
      </div>
    </div>
  );
};

export {HeaderUserMenu};
