import './header.scss';
import clsx from 'clsx';
import {toAbsoluteUrl} from 'core/helpers';
import {useLayout} from '../../core';
import {DefaultTitle} from './page-title/DefaultTitle';
import {Topbar} from './Topbar';
import {Link} from 'react-router-dom';
import {useThemeMode} from '../../partials/theme-mode/ThemeModeProvider';
import SVG from 'react-inlinesvg';
import React from 'react';

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout();
  const {header} = config;
  const {menuMode} = useThemeMode();

  const logoUrl = menuMode === 'light' ? '/media/logos/2024/full-color-in-white.svg' : '/media/logos/2024/full-color-in-black.svg';

  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
    >
      {/* begin::Container */}
      <div
        className={clsx(
          classes.headerContainer.join(' '),
          'd-flex align-items-stretch justify-content-between'
        )}
      >
        {/* begin::Wrapper */}
        <div className={'d-flex align-items-stretch justify-content-between flex-lg-grow-1'}>
          <div className='logo' id='kt_header_nav'>
            <Link to='/'>
              <SVG src={toAbsoluteUrl(logoUrl)} className='h-41' />
            </Link>
          </div>

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}
          <Topbar />
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Container */}
    </div>
  );
}
