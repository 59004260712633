import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../core/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../core/layouts/layout2/core'
import { AuthInit } from './modules/auth'
import {useDispatch} from 'react-redux'
import { fetchAllUsersAction, fetchAllTeamsAction } from '../core/store/core/coreActions'
import { SnackNotificationProvider } from './layout/_core/SnackNotificationProvider'
import { closeSentry, initSentry } from '../core/monitoring/sentry'
import { ErrorBoundary } from '@sentry/react'
import { Error500 } from './modules/errors/components/Error500'

const App = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch<any>(fetchAllUsersAction());
    dispatch<any>(fetchAllTeamsAction());
  }, [dispatch]);

  useEffect(() => {
    const reinitSentry = async () => {
      await closeSentry(100)
      await initSentry()
    }
    if((window as any)?.SENTRY_DSN && (window as any).SENTRY_TRACE_TARGET){
      reinitSentry()
    }
  },[(window as any)?.SENTRY_DSN, (window as any).SENTRY_TRACE_TARGET])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <SnackNotificationProvider>
            <ErrorBoundary fallback={<Error500 />}>
              <AuthInit>
                <Outlet />
              </AuthInit>
            </ErrorBoundary>
          </SnackNotificationProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>

  )
}

export { App }
