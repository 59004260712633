import {createSlice} from '@reduxjs/toolkit';

const initialSheetState = {
  listLoading: false,
  actionsLoading: false,
  sheets: [],
  sheet: null,
  countSheets: 0,
  pages: [],
  workspaces: [],
  countWorkspaces: 0,
  currentWorkspace: null,
  countPages: 0,
  error: null,
};

export const callTypes = {
  list: 'list',
  action: 'action',
};


export const sheetSlice = createSlice({
  name: 'sheet',
  initialState: initialSheetState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    endCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },

    sheetsFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.sheets = data.data ? data.data : [];
      if (data.objects) {
        state.countSheets = data.objects.total;
      } else if (data.total) {
        state.countSheets = data.total;
      } else {
        state.countSheets = 0;
      }
    },

    sheetFetched: (state, action) => {
      const {data} = action.payload;
      state.listLoading = false;
      state.error = null;
      state.sheet = data.data ? data.data : [];
    },

    

  },
});
