import * as auth from '../store/auth';

import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';
import {coreSlice} from './core/coreSlice';
import {departmentSlice} from 'app/modules/admin/departments/stores/slice';
import {docSlice} from 'app/modules/work/document/stores/slice';
import {meetingSlice} from 'app/modules/meeting/redux/meetingSlice';
import {slice as myWorkSlice} from 'app/modules/dashboard/stores/slice';
import {notificationSlice} from 'app/modules/notifications/stores/slice';
import {permissionSlice} from 'app/modules/admin/permission/stores/slice';
import {slice as profileSlice} from 'app/modules/profile/stores/slice';
import {sheetSlice} from 'app/modules/work/sheet/stores/sheetSlice';
import {teamSlice} from 'app/modules/admin/teams/stores/slice';
import {templateSlice} from 'app/modules/work/sheet/stores/template/templateSlice';
import {userSlice} from 'app/modules/admin/users/stores/slice';
import {userStateSlice} from './userState/userStateSlice';
import {workFolderSlice} from 'app/modules/work/folder/stores/slice';
import {workSlice} from 'app/modules/work/stores/slice';
import {slice as workflowSlice} from 'app/modules/admin/workflows/stores/slice';
import { appSlice } from 'app/modules/application/stores/slice';
import { slice as myHomeSlice } from 'app/modules/home/stores/slice';
import { slice as storeAppSlice } from 'app/modules/application/stores/storeApp/slice';

export const appReducer = combineReducers({
  auth: auth.reducer,
  core: coreSlice.reducer,
  userState: userStateSlice.reducer,
  meeting: meetingSlice.reducer,
  sheet: sheetSlice.reducer,
  work: workSlice.reducer,
  team: teamSlice.reducer,
  user: userSlice.reducer,
  department: departmentSlice.reducer,
  myWork: myWorkSlice.reducer,
  profile: profileSlice.reducer,
  workFolder: workFolderSlice.reducer,
  notification: notificationSlice.reducer,
  template: templateSlice.reducer,
  doc: docSlice.reducer,
  workflow: workflowSlice.reducer,
  permission: permissionSlice.reducer,
  app: appSlice.reducer,
  myHome: myHomeSlice.reducer,
  storeApp: storeAppSlice.reducer
});

export const rootReducer = (state, action) => {
  // reset root state when user logout
  if (action.type === auth.actionTypes.Logout) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export function* rootSaga() {
  yield all([auth.saga()]);
}
