import { getApiV2, postApiIntegration, postApiV2} from 'core/store/api';
import { cloneDeep } from 'lodash';

const endPoints = {
  getPage: (id) => `/api/page/${id}`,
  updatePage: (id) => `/api/page/${id}`,
  getDocumentObjects: (documentId) => `/api/document/${documentId}/objects`,
  createDocumentObjects: (documentId) => `/api/document/${documentId}/objects`,
  updateDocumentObjects: (documentId, objectId) => `/api/document/${documentId}/objects/${objectId}`,
  aiAssistant: (action, prompt) => `/api/ai-assistant/${action}`
};



export function getPage(params) {
  return getApiV2(endPoints.getPage(params.id));
}

export function updatePage(params) {
  return postApiV2(endPoints.updatePage(params.id), params);
}


export function getDocumentObjects(params) {
  return getApiV2(endPoints.getDocumentObjects(params.documentId));
}


export function updateDocumentObject(params) {
  let request = cloneDeep(params);
  delete(request.documentId);
  delete(request.objectId);
  return postApiV2(endPoints.updateDocumentObjects(params.documentId, params.objectId), request);
}



export function createDocumentObject(params) {
  let request = cloneDeep(params);
  request.document_id = params.documentId;
  delete(request.documentId);
  delete(request.objectId);
  return postApiV2(endPoints.createDocumentObjects(params.documentId), request);
}

export function callAiAssistant(params) {
  let request = {
    prompt: params.prompt
  };
  return postApiIntegration(endPoints.aiAssistant(params.action), request);
}

