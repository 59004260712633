import axios from "axios";
import store from "./store";

function getDomain() {
  if (window.APP_DOMAIN && window.APP_DOMAIN !== '') {
    return window.APP_DOMAIN;
  } else {
    var subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
    if (subdomain) {
      return subdomain;
    } else {
      return '';
    }
  }

}

const apiV2Url = (endpoint) => {
  return window.API_V2_BASE_URL + window.API_V2_PATH + endpoint;
}


const apiIntegrationUrl = (endpoint) => {
  return window.API_INTEGRATION_BASE_URL + window.API_INTEGRATION_PATH + endpoint;
}

const apiUrl = (endpoint) => {
  return window.API_BASE_URL + window.API_V1_PATH + endpoint;
}


export function postApiV2(endpoint, data = {}) {
  return axios({
    method: "post",
    url: apiV2Url(endpoint),
    data: data,
  });
}


export function putApiV2(endpoint, data = {}) {
  return axios({
    method: "put",
    url: apiV2Url(endpoint),
    data: data,
  });
}


export function deleteApiV2(endpoint, params = {}) {
  return axios({
    method: "delete",
    url: apiV2Url(endpoint),
    params: params
  });
}


export function getApiV2(endpoint, params = {}) {
  
  return axios({
    method: "get",
    url: apiV2Url(endpoint),
    params: params,
  });
}


export function postApiIntegration(endpoint, data = {}) {
  return axios({
    method: "post",
    url: apiIntegrationUrl(endpoint),
    data: data,
  });
}