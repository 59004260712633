/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react';
import clsx from 'clsx';

import {toAbsoluteUrl} from 'core/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {useSession} from 'core/store/hooks';
import {callAiAssistantAction} from '../stores/actions';
import {markdownToHtml} from 'app/common/_helpers';
import {useTranslate} from 'core/i18n/i18nProvider';
import {Button} from 'react-bootstrap';
const defaultMessages = [
  {
    user: 0,
    type: 'in',
    text: 'Hãy hỏi tôi bất cứ điều gì',
    //time: '2 mins',
  },
];

const bufferMessages = defaultMessages;
const optimiAssistantInfo = {
  _id: 0,
  name: 'Optimi',
  avatar: toAbsoluteUrl('/media/logos/logo-144.png'),
  online: true,
};

const ChatInner = ({isDrawer = false}) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState(false);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState(bufferMessages);
  const [userInfos, setUserInfos] = useState([]);
  const allUsers = useSelector((state) => state.core.allUsers);
  const {user: currentUser} = useSession();
  const dispatch = useDispatch();
  const {t} = useTranslate();
  const [typings, setTypings] = useState([]);

  useEffect(() => {
    if (allUsers) {
      let _userInfos = {};
      _userInfos[optimiAssistantInfo._id] = optimiAssistantInfo;
      allUsers.forEach((user) => {
        _userInfos[user._id] = user;
      });
      setUserInfos(_userInfos);
    }
  }, [allUsers]);
  const sendMessage = () => {
    const newMessage = {
      user: currentUser._id,
      type: 'out',
      text: message,
      //time: 'Just now',
    };

    bufferMessages.push(newMessage);
    setMessages(bufferMessages);
    toggleChatUpdateFlat(!chatUpdateFlag);
    setMessage('');
    setTypings([optimiAssistantInfo._id]);
    dispatch(
      callAiAssistantAction({
        action: 'create-content',
        prompt: message,
      })
    ).then((result) => {
      if (result?.data?.data?.model_text) {
        setTypings([]);
        const html = markdownToHtml(result?.data?.data?.model_text);
        const replyMessage = {
          user: 0,
          type: 'in',
          text: html,
          //time: 'Just now',
        };
        bufferMessages.push(replyMessage);
        setMessages(() => bufferMessages);
        toggleChatUpdateFlat((flag) => !flag);
      } else {
      }
    });
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMessage();
    }
  };

  const renderTypings = () => {
    if (!typings.length) return null;
    return (
      <div
        className='d-flex align-items-center mt-3'
        data-kt-element='typing-indicator'
        data-kt-initialized='1'
      >
        {typings.map((userId) => {
          let user = userInfos[userId] || {};
          return <span key={userId}>{user.name || ''} </span>;
        })}
        {' đang trả lời... '}
        <div className='spinner-border text-dark spinner-border-sm ms-2' role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      </div>
    );
  };

  return (
    <div
      className='card-body h-100 d-flex flex-column'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y me-n5 pe-5 flex-grow-1', {'h-300px h-lg-auto': !isDrawer})}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >
        {messages.map((message, index) => {
          return (
            <Message key={index} message={message} userInfos={userInfos} isDrawer={isDrawer} />
          );
        })}
      </div>

      <div
        className='card-footer pt-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className='form-control mb-3'
          rows={1}
          data-kt-element='input'
          placeholder={t('assistant.message.place_holder')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1 d-none'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1 d-none'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-upload fs-3'></i>
            </button>
          </div>
          <div className='d-flex w-100 align-items-center justify-content-between'>
            <div className=' fs-7 text-muted'>{renderTypings()}</div>
            <button
              className='btn btn-primary'
              type='button'
              data-kt-element='send'
              onClick={sendMessage}
            >
              {t('common_send')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export {ChatInner};

const Avatar = ({user}) => {
  return (
    <>
      {user?.avatar ? (
        <div className='symbol symbol-35px symbol-label symbol-circle'>
          <img src={user.avatar} alt={user?.name?.[0] || ''} className='w-100' />
        </div>
      ) : (
        <div className={clsx('symbol-label fs-3 symbol-circle', `bg-light`, `text`)}>
          {user?.name?.[0] || ''}
        </div>
      )}
    </>
  );
};

const Message = ({message, userInfos, isDrawer}) => {
  const userInfo = userInfos[message.user];
  const state = message.type === 'in' ? 'info' : 'primary';
  const templateAttr = {};
  if (message.template) {
    Object.defineProperty(templateAttr, 'data-kt-element', {
      value: `template-${message.type}`,
    });
  }
  const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
    message.type === 'in' ? 'start' : 'end'
  } mb-10`;
  return (
    <div
      key={`message${message.id}`}
      className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
      {...templateAttr}
    >
      <div
        className={clsx(
          'd-flex flex-column align-items',
          `align-items-${message.type === 'in' ? 'start' : 'end'}`
        )}
      >
        <div className='d-flex align-items-center mb-2'>
          {message.type === 'in' ? (
            <>
              <div className='symbol  symbol-35px symbol-circle '>
                <Avatar user={userInfo} />
              </div>
              <div className='ms-3'>
                <a href='#' className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'>
                  {userInfo?.name}
                </a>
                <span className='text-muted fs-7 mb-1'>{message.time}</span>
              </div>
            </>
          ) : (
            <>
              <div className='me-3'>
                <span className='text-muted fs-7 mb-1'>{message.time}</span>
                <a href='#' className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'>
                  You
                </a>
              </div>
              <div className='symbol  symbol-35px symbol-circle '>
                <Avatar user={userInfo} />
              </div>
            </>
          )}
        </div>

        <div
          className={clsx(
            'p-5 rounded',
            `bg-light-${state}`,
            'text-dark mw-lg-400px',
            `text-${message.type === 'in' ? 'start' : 'end'}`
          )}
          data-kt-element='message-text'
          dangerouslySetInnerHTML={{__html: message.text}}
        ></div>
        <div className='d-flex justify-content-end w-100 '>
          <CopyButton message={message.text} />
        </div>
      </div>
    </div>
  );
};

function CopyButton({message}) {
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(message);
    setShowCopyMessage(true);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
  };

  return (
    <div style={{position: 'relative'}}>
      {showCopyMessage && <span className='copy-message text-muted fs-8' style={{position:'absolute', top: 20}}>Copied</span>}
      <Button
        className='btn btn-xs btn-icon bg-transparent btn-active-light w-20px h-20px'
        type='button'
        variant='light'
        onClick={handleCopy}
      >
        <i className='las la-copy text-muted'></i>
      </Button>
    </div>
  );
}
