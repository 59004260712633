import React, {FC, useEffect, useState} from 'react';
import clsx from 'clsx';
import {KTSVG, toAbsoluteUrl} from 'core/helpers';
import {HeaderUserMenu} from '../../../../partials';
import {useLayout} from '../../core';
import {useSession} from 'core/store/hooks';
import NotificationDrawer from 'app/modules/notifications/NotificationDrawer';
import AssistantDrawer from 'app/modules/assistant/chat/AssistantDrawer';
import {MenuComponent} from '../../assets/ts/components';
import {Dropdown} from 'react-bootstrap';
import ListAppDropdown from './ListAppDropdown';

const itemClass = 'ms-1 ms-lg-3',
  btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px',
  userAvatarClass = 'symbol-30px symbol-md-30px';

const Topbar: FC = () => {
  const {config} = useLayout();
  const {user} = useSession();
  const [showNotificationDrawer, setShowNotificationDrawer] = useState(false);
  const [showAssistantDrawer, setShowAssistantDrawer] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization();
    }, 100);
  }, []);

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='d-flex justify-content-center align-items-center' style={{zIndex: 910}}>
        {/* begin::Menu wrapper */}
        <div
          className='d-flex justify-content-center align-items-center me-3 cursor-pointer w-30px h-30px'
          onClick={() => setShowAssistantDrawer(true)}
        >
          <span className='icon-face fs-icon' />
        </div>
        <AssistantDrawer
          show={showAssistantDrawer}
          handleClose={() => setShowAssistantDrawer(false)}
        />
        {/* end::Menu wrapper */}
      </div>
      {/* NOTIFICATIONS */}
      <div className='d-flex justify-content-center align-items-center'>
        <div
          className='d-flex justify-content-center align-items-center me-3 cursor-pointer w-30px h-30px'
          onClick={() => setShowNotificationDrawer(true)}
        >
          <span className='icon-bell fs-icon' />
        </div>
        <NotificationDrawer
          show={showNotificationDrawer}
          handleClose={() => setShowNotificationDrawer(false)}
        />
      </div>
      <div className='d-flex justify-content-center align-items-center'>
        <div className='d-flex justify-content-center align-items-center me-3 cursor-pointer w-30px h-30px'>
          <ListAppDropdown />
        </div>
      </div>
      <div className={clsx('d-flex align-items-center d-none', itemClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(btnClass, 'position-relative')}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <i className='bi bi-grid fs-2' />
        </div>
        {/* end::Menu wrapper */}
      </div>

      {/* begin::Theme mode */}
      {/* <div className={clsx('d-flex align-items-center', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={btnClass} />
      </div> */}
      {/* end::Theme mode */}

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center user-select-none', itemClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div className='d-flex gap-2 h-30px align-items-center'>
            {user?.avatar ? (
              <img
                src={user?.avatar}
                alt='avatar'
                className='position-relative h-30px w-30px rounded-circle'
                style={{zIndex: 10}}
                onError={(e: any) => {
                  e.target.style.display = 'none';
                }}
              />
            ) : (
              <span className='symbol-label bg-light-danger text-danger fw-bold'>
                {user?.name?.[0] || ''}
              </span>
            )}
            <span className=' align-middle'>{user?.name}</span>
          </div>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}

      {/* begin::Aside Toggler */}
      {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )}
    </div>
  );
};

export {Topbar};
